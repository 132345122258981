<template>
    <div>
        <v-header></v-header>
        <div class="container">
            <span class="title">常见问题</span>
            <template v-for="(item,index) in list">
                <van-collapse v-model="activeNames" accordion :border="false">
                    <van-collapse-item :title="item.title" :name="index">
                        <div v-html="item.content"></div>
                    </van-collapse-item>
                </van-collapse>
            </template>
        </div>
    </div>
</template>

<script>
    import {user} from "../../api";

    export default {
        name: "about",
        data(){
            return{
                activeNames: [0],
                list: []
            }
        },
        created(){
            this.getData();
        },

        methods: {
            async getData(){
                let arr = ['常见问题'];
                // let arr = [`ABOUNT_PERMISSION`,`ABOUNT_VERIFY`,`ABOUNT_OFF_THE_SHELF`,`ABOUNT_PRINCIPLE`];
                const res = await user.question();

                this.list = res.data['常见问题'];
                // console.log(this.list);
            }
        }
    }
</script>

<style scoped lang="less">
    .container{
        height: calc(100vh - var(--header-height));
        overflow-y: auto;
        padding: var(--header-height) 28px 24px;
        .title{
            font-weight: bold;
            padding: 10px 0;
        }
        .content{
            p,span{
                letter-spacing: 4px;
                color: #999 !important;
            }
        }
    }
</style>
